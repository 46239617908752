import React from 'react';
import Axios from 'axios';

import { Loader } from '@googlemaps/js-api-loader';
import { withStyles, Typography, Button } from '@material-ui/core';
import { withContext, format } from 'kn-react';

import { SessionContext } from '../SessionProvider/SessionProvider';


const AREA_MAP_ID = 'area-map';
const MIN_VERTICES = 3;


class AreaMap extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      google: null,
      map: null,
      latLng: null,
      markers: [],
      polygon: null,
      areaSquareMeters: null,
      areaSquareFeet: null
    }
  }


  componentDidMount = () => {
    return this.setState(
      { latLng: this.props.latLng },
      this.initializeMap
    );
  }


  initializeMap = () => {
    const { latLng } = this.state;

    return new Loader({
      apiKey: this.props.mapsApiKey,
      version: "weekly",
      libraries: ['geometry']
    }).load().then(google => {
      const map = new google.maps.Map(
        document.getElementById( AREA_MAP_ID ), {
        center: latLng,
        zoom: 20,
        mapTypeId: 'satellite'
      });


      // Add click event listener
      map.addListener(
        "click",
        event => this.addMarker( event.latLng )
      );


      return this.setState(
        { google, map },
        this.setInitialMarkers
      )
    });
  }


  createMarker = (position, additionalProps={}) => (
    new this.state.google.maps.Marker({
      ...additionalProps,
      position,
      map: this.state.map
    })
  )


  setInitialMarkers = () => {
    const houseMarkerIcon = {
      icon: `https://storage.googleapis.com/go-boost-forms-public/house_pin.png`,
      title: "Customer's house",
    };

    let markers = [
      this.createMarker(this.state.latLng, houseMarkerIcon)
    ];


    if( this.props.polygon.length ){
      this.props.polygon.forEach(
        (p, i) => {
          const pMarker = this.createMarker(
            p
          );

          this.addMarkerRemovalListener(
            pMarker,
            i + 1
          );

          markers.push(
            pMarker
          );
        }
      );
    }


    return this.setState(
      { markers },
      this.createPolygon
    )
  }


  addMarkerRemovalListener = (marker, index) => (
    marker.addListener('click', () => {
      this.removeMarker(
        index
      )
    })
  )


  addMarker = (latLng, additionalProps={}) => {
    const { google, map } = this.state;

    const marker = new google.maps.Marker({
      ...additionalProps,
      position: latLng,
      map
    });


    // Add removal event listener
    if( this.state.markers.length ){
      this.addMarkerRemovalListener(
        marker,
        this.state.markers.length - 1
      );
    }

    const markers = [ ...this.state.markers ];
    markers.push( marker );


    return this.setState(
      { markers },
      this.createPolygon
    );
  }


  removeMarker = markerIndex => {
    const marker = this.state.markers[ markerIndex ];
    marker.setMap(null);

    const markers = [ ...this.state.markers ];
    markers.splice(markerIndex, 1);

    return this.setState(
      { markers },
      this.createPolygon
    );
  }


  createPolygon = () => {
    const { google, map } = this.state;

    if( this.state.polygon ){
      this.state.polygon.setMap( null );
    }


    // Skip first marker since it marks the house
    const polygonMarkers = this.state.markers.slice(1);

    if( polygonMarkers.length < MIN_VERTICES ){
      const questionAnswers = {
        markers: this.state.markers,
        polygon: null,
        areaSquareMeters: null,
        areaSquareFeet: null,
        latLng: this.state.latLng,
      };

      return this.setState({
        ...questionAnswers
      });
    }


    const vertices = polygonMarkers.map(
      m => ({ lat: m.position.lat(), lng: m.position.lng() })
    );

    const polygon = new google.maps.Polygon({
      paths: vertices,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });

    polygon.setMap(map);


    const areaSquareMeters = google.maps.geometry.spherical.computeArea(
      polygon.getPath()
    );

    const areaSquareFeet = areaSquareMeters * 10.7639;


    const questionAnswers = {
      markers: this.state.markers,
      polygon,
      areaSquareMeters,
      areaSquareFeet,
      latLng: this.state.latLng
    };

    return this.setState(
      { ...questionAnswers }
    );
  }


  onClearPins = () => {
    const polygonMarkers = this.state.markers.slice(1);

    polygonMarkers.forEach(
      m => m.setMap(null)
    );

    return this.setState(
      { markers: [ this.state.markers[0] ] },
      this.createPolygon
    );
  }



  onClearLastPin = () => {
    if( this.state.markers.length < 2 ){
      return;
    }

    const markerToRemove = this.state.markers[
      this.state.markers.length - 1
    ];
    markerToRemove.setMap( null );

    const markers = [ ...this.state.markers ];
    markers.splice(markers.length - 1, 1);

    return this.setState(
      { markers },
      this.createPolygon
    );
  }


  render(){
    const { classes } = this.props;

    const fixedAreaSquareMeters = ( this.state.areaSquareMeters || 0 ).toFixed( 2 ) || 0;
    const fixedAreaSquareFeet = ( this.state.areaSquareFeet || 0 ).toFixed( 2 ) || 0;

    return (
      <div className={classes.root}>
        <div className={classes.areaLabel}>
          <Typography variant="h6">
            Estimated Area: { format.commas( fixedAreaSquareFeet ) } sq. ft. ({ format.commas( fixedAreaSquareMeters ) } sq. m.)
          </Typography>

          <Button
            variant='outlined'
            color='primary'
            onClick={this.onClearPins}
            size='small'
            className={classes.button}
          >
            Clear Pins
          </Button>

          <Button
            variant='outlined'
            color='primary'
            onClick={this.onClearLastPin}
            size='small'
            className={classes.button}
          >
            Remove Last Pin
          </Button>
        </div>

        <div
          id={AREA_MAP_ID}
          className={classes.mapDiv}
        >
        </div>
      </div>
    )
  }
}


const styles = theme => ({
  mapDiv: {
    height: 500,
    width: '75%',
    marginLeft: '12.5%',
    marginTop: 10,
  },
  root: {
    marginTop: 15,
    marginBottom: 25,
  },
  areaLabel: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    marginLeft: 15
  }
})

AreaMap.defaultProps = {
  latLng: {},
  polygon: [],
}


export default withContext(
  SessionContext,
  withStyles(styles)(
    AreaMap
  )
)