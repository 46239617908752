import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, withStyles } from '@material-ui/core';
import { withContext } from 'kn-react';
// import { PromiseButton } from 'go-boost-library-react';
import { CompanyDetailContext } from '../CompanyDetailProvider/CompanyDetailProvider';
import { AREA_MAP_KEY } from '../Response/StepKeys';


class QuoteEstimate extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      quoteFormulaJson: []
    };
  }





  componentDidUpdate = oldProps => {
    if(
      this.props.companyDetails !== oldProps.companyDetails ||
      this.props.open !== oldProps.open
    ){
      return this.setState({
        quoteFormulaJson: [
          ...(
            this.props.companyDetails.quoteFormulaJson ||
            []
          )
        ]
      });
    }
  }


  onChange = (e, key, choiceLabel=null) => {
    const question = this.state.quoteFormulaJson.find(
      q => q.key === key
    );

    const quoteFormulaJson = [
      ...this.state.quoteFormulaJson
    ];

    const keyIndex = quoteFormulaJson.findIndex(
      q => q.key === key
    );

    if( keyIndex < 0 ){
      return;
    }

    let valueToChange = { ...quoteFormulaJson[ keyIndex ] };
    if( choiceLabel ){
      const choiceIndex = question.choices.find(
        c => c.label === choiceLabel
      );

      if( choiceIndex < 0 ){
        return;
      }

      valueToChange = quoteFormulaJson[ keyIndex ].choices[ choiceIndex ];
    }

    valueToChange.value = e.target.value;
    quoteFormulaJson[ keyIndex ] = valueToChange;


    return this.setState({ quoteFormulaJson });
  }


  fetchValue = (key, choiceLabel=null) => {
    const question = this.state.quoteFormulaJson.find(
      q => q.key === key
    );

    if( !question ){
      return '';
    }


    if( !choiceLabel ){
      return question.value || '';
    }

    const choiceIndex = question.choices.findIndex(
      c => c.label === choiceLabel
    );

    if( choiceIndex < 0 ){
      return '';
    }


    return question.choices[ choiceIndex ].value || '';
  }


  render(){
    const { classes } = this.props;

    // const questionsWithChoices = this.state.quoteFormulaJson.filter(
    //   q => q.choices && q.choices.length
    // );

    return(
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Quote Estimate Formula
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={8}>
            <Grid item xs={12} className={classes.grid}>
              {/* <Grid item xs={6}>
                Price per square foot
              </Grid> */}

              <Grid item xs={12}>
                <TextField
                  type='number'
                  className={classes.textField}
                  label='Price per square foot'
                  fullWidth
                  value={this.fetchValue(AREA_MAP_KEY)}
                  variant='outlined'
                  onChange={e => this.onChange(e, AREA_MAP_KEY)}
                  InputProps={{
                    startAdornment: '$'
                  }}
                />
              </Grid>
            </Grid>

            {
                // questionsWithChoices.map(
                //   q => (
                //     <Grid item xs={12} >
                //       <Grid item xs={12}>
                //         { q.question }
                //       </Grid>

                //       {
                //         q.choices.map(
                //           c => (
                //             <Grid item xs={12} className={classes.grid}>
                //               <Grid item xs={6}>
                //                 { c.label }
                //               </Grid>

                //               <Grid item xs={6}>
                //                 <TextField
                //                   fullWidth
                //                   value={this.fetchValue(q.key, c.label)}
                //                   variant='outlined'
                //                   onChange={e => this.onChange(e, q.key, c.label)}
                //                 />
                //               </Grid>
                //             </Grid>
                //           )
                //         )
                //       }
                //     </Grid>
                //   )
                // )
              }
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.props.onClose}
          >
            Close
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.onSave( this.state.quoteFormulaJson )}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}



QuoteEstimate.defaultProps = {
  open: false,
  onClose: () => {},
  onSave: () => {}
}


const styles = theme => ({
  grid: {
    display: 'flex'
  },
  textField: {
    marginTop: 15
  }
})



export default withContext(
  CompanyDetailContext,
  withStyles(styles)(
    QuoteEstimate
  )
)