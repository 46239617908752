import React from 'react';
import Axios from 'axios';
import Moment from 'moment';

import { withContext, MaxWidth, format } from 'kn-react';
import { Link } from 'react-router-dom';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, withStyles, Typography, Button, TableFooter, TablePagination, IconButton, Tooltip } from '@material-ui/core';
import { Share } from '@material-ui/icons';

import { SessionContext } from '../SessionProvider/SessionProvider';
import { CompanyDetailContext } from '../CompanyDetailProvider/CompanyDetailProvider';
import { CONTACT_KEY, ADDRESS_KEY, TIMELINE_KEY, AREA_MAP_KEY } from '../Response/StepKeys';
import ResponseModel from '../Response/ResponseModel';
import QuoteEstimate from '../QuoteEstimate/QuoteEstimate';
import history from '../history';

class Home extends React.Component {
  state = {
    total: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    page: 0,
    responses: [],
    isOpen: false
  }


  componentDidMount = () => {
    return this.fetchResponses();
  }



  fetchResponses = () => {
    return Axios.get(
      `/api/responses/companies/${ this.props.companyId }`,
      {
        params: {
          limit: this.state.rowsPerPage,
          page: this.state.page
        }
      }
    ).then(response => {
      const responses = response.data.responses.map(
        r => ResponseModel.fromJSON( r )
      );

      const total = response.data.response_count;
      console.log('response.data', response.data)
      console.log('total', total)

      return this.setState({ responses, total });
    })
  }


  changePagination = newState => (
    this.setState(
      newState,
      this.fetchResponses
    )
  )


  onSaveQuoteEstimate = quoteFormulaJson => {
    return this.setState(
      { isOpen: false },
      () => this.props.onUpdateCompanyDetail(
        quoteFormulaJson
      )
    )
  }


  render(){
    const { classes } = this.props;

    const emailHref = (
      `mailto:someone@email.com?subject=${ this.props.companyDetails.name } Jobs Dashboard&body=Check out our Contact Form Job Dashboard.`
    );

    return(
      <MaxWidth maxWidth={750}>
        <div className={classes.body}>
          <div className={classes.logoDiv}>
            <img
              src={this.props.companyDetails.logoUrl || ''}
              className={classes.logo}
            />

            <Tooltip title='Share Jobs Dashboard'>
              <IconButton
                style={{ float: 'left' }}
                component={'a'}
                href={emailHref}
              >
                <Share color='primary'/>
              </IconButton>
            </Tooltip>
          </div>

          <div className={classes.header}>
            <div className={classes.reportHeader}>
              <Typography variant='h6'>
                { this.props.companyDetails.name } Jobs
              </Typography>
            </div>

            <div className={classes.quote}>
              <Button
                color="primary"
                variant='outlined'
                onClick={() => this.setState({ isOpen: true })}
              >
                Edit Quote Formula
              </Button>
            </div>
          </div>

          <Paper className={classes.paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Customer Name
                  </TableCell>
                  <TableCell>
                    Customer Address
                  </TableCell>
                  <TableCell>
                    Estimated Quote
                  </TableCell>
                  <TableCell>
                    Date &amp; Time Submitted
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  this.state.responses.map((r, i) => {
                    const contactQuestion = r.responseJson.find(
                      v => v.key === CONTACT_KEY
                    );

                    const addressQuestion = r.responseJson.find(
                      v => v.key === ADDRESS_KEY
                    );

                    const areaMapQuestion = r.responseJson.find(
                      v => v.key === AREA_MAP_KEY
                    );

                    const squareFeet = (
                      areaMapQuestion ?
                        areaMapQuestion.areaSquareFeet
                      :
                        0
                    ) || 0;

                    const responseLink = `/responses/${ r.id }`;

                    return (
                      <TableRow
                        className={classes.tableRow}
                        key={i}
                        onClick={() => history.push( responseLink )}
                      >
                        <TableCell>
                          <Link to={responseLink}>
                            { contactQuestion.value.firstName } { contactQuestion.value.lastName }
                          </Link>
                        </TableCell>
                        <TableCell>
                          { addressQuestion.value.fullAddress }
                        </TableCell>
                        <TableCell>
                          { format.money( squareFeet * this.props.pricePerSquareFoot() ) }
                        </TableCell>
                        <TableCell>
                          { Moment.utc(r.createdAt.toString()).local().format('MM-DD-YYYY hh:mm:ss a') }
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
              <TableFooter>
								<TableRow>
									<TablePagination
										count={this.state.total || 0}
										rowsPerPage={this.state.rowsPerPage}
										page={this.state.page}
										onChangeRowsPerPage={e => this.changePagination({ rowsPerPage: e.target.value })}
										onChangePage={(e, p) => this.changePagination({ page: p })}
										rowsPerPageOptions={this.state.rowsPerPageOptions}
									/>
								</TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </div>

        <QuoteEstimate
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          onSave={this.onSaveQuoteEstimate}
        />
      </MaxWidth>
    )
  }
}


const styles = theme => ({
  body: {
    marginTop: 100
  },
  paper: {
    marginTop: 15
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'whitesmoke'
    }
  },
  logo: {
    maxWidth: 250
  },
  logoDiv: {
    width: '100%',
    textAlign: 'center'
  },
  quote: {
    width: '50%',
    textAlign: 'right'
  },
  header: {
    display: 'flex'
  },
  reportHeader: {
    width: '50%'
  }
})


export default withStyles(styles)(
  withContext(
    CompanyDetailContext,
    SessionContext,
    Home
  )
)