import React from 'react';

import { MuiThemeProvider, withStyles } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles'
import { withContext } from 'kn-react';

import { CompanyDetailContext } from '../CompanyDetailProvider/CompanyDetailProvider';


const createTheme = (primaryColor, secondaryColor) => ({
  "appTitle": "GoBoost Pavement Form",
  "muiTheme": {
    "palette": {
      "primary": {
        "main": primaryColor || "#5A72EE",
      },
      "secondary": {
        "main": secondaryColor || "#5E5F64",
      }
    }
  },
  "favicon": "https://storage.googleapis.com/go-boost-partners-public/themes/go_boost/go_boost_favicon.png",
  "pageTitle": "GoBoost Pavement Form"
})

const createDefaultState = theme => ({
  'theme': {
    muiTheme: createMuiTheme({
      ...theme,
      ...theme.muiTheme,
      typography: {
        ...(theme.muiTheme.typography || {}),
        useNextVariants: true,
      }
    })
  }
});


export const ThemeContext = React.createContext(
  createDefaultState( createTheme() )
);

class ThemeProvider extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      ...createDefaultState(
        createTheme(
          this.props.companyDetails.primaryColor,
          this.props.companyDetails.secondaryColor,
        )
      )
    };
  }

  componentDidMount = () => {
    this.updateTitle();
    this.updateFavicon();
  }

  componentDidUpdate = oldProps => {
    if( oldProps.companyDetails !== this.props.companyDetails ){
      return this.setState(
        {
          ...createDefaultState(
            createTheme(
              this.props.companyDetails.primaryColor,
              this.props.companyDetails.secondaryColor,
            )
          )
        },
        () => {
          this.updateTitle();
          this.updateFavicon();
        }
      )
    }
  }

  updateTitle = () => {
    const { theme } = this.state;
    window.document.title = theme.pageTitle || 'GoBoost';
  }

  updateFavicon = () => {
    const { theme } = this.state;

    if(!theme.favicon) return;

    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = theme.favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  render(){
    const { theme } = this.state;

    return (
      <ThemeContext.Provider
        value={{
          ...this.state,
        }}
      >
        <MuiThemeProvider theme={theme.muiTheme}>
          { this.props.children }
        </MuiThemeProvider>
      </ThemeContext.Provider>
    );
  }
}


ThemeProvider.defaultProps = {
  onThemeError: () => {},
  companyDetails: {}
};


const styles = theme => ({
  barColorPrimary: {
    backgroundColor: '#CCCCCC'
  }
});

export default withContext(
  CompanyDetailContext,
  withStyles(styles)(
    ThemeProvider
  )
);