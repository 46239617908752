import React from 'react';
import Axios from 'axios';

import { Link } from 'react-router-dom';
import { withContext, MaxWidth, format } from 'kn-react';
import { Button, withStyles, Typography, Grid, LinearProgress, IconButton, Tooltip, Paper, List, ListItem, ListItemText } from '@material-ui/core';
import { Assignment } from '@material-ui/icons';

import { SessionContext } from '../SessionProvider/SessionProvider';
import { CompanyDetailContext } from '../CompanyDetailProvider/CompanyDetailProvider';
import { CONTACT_KEY, ADDRESS_KEY, TIMELINE_KEY, AREA_MAP_KEY, PHOTO_KEY, VIDEO_KEY } from './StepKeys';
import AreaMapDialog from '../AreaMap/AreaMapDialog';
import GoogleMapsMap from '../GoogleMapsMap/GoogleMapsMap';
import ResponseModel from './ResponseModel';


const PREMADE_RESPONSES = [
  {
    primary: 'Instant Quote',
    secondary: 'Automatic quote based on the price per square foot you provided',
    createHref: options => `mailto:${ options.email }?subject=Paving Quote&body=Hi ${ options.firstName } - I had a chance to review your request for an estimate. Based on your estimated paving area, the job will start around ${ format.money( options.estimate ) }. Let's talk about getting you on our schedule.`
  },
  {
    primary: 'High Range Instant Quote',
    secondary: 'Automatic quote based on the price per square foot you provided, with language stating the job may have a higher final cost',
    createHref: options => `mailto:${ options.email }?subject=Paving Quote&body=Hi ${ options.firstName } - I had a chance to review your request for an estimate. Based on your estimated paving area, the job will start around ${ format.money( options.estimate ) } but will likely range closer to ${ format.money( options.estimate + 1000 ) }. Let's talk about any questions you may have and find a time to get you on our schedule.`
  },
  {
    primary: 'Low Range Instant Quote',
    secondary: 'Automatic quote based on the price per square foot you provided, with language stating the job may have a lower final cost',
    createHref: options => `mailto:${ options.email }?subject=Paving Quote&body=Hi ${ options.firstName } - I had a chance to review your request for an estimate. Based on your estimated paving area, the job will start around ${ format.money( options.estimate - 500 ) } but has the chance of coming in closer to ${ format.money( options.estimate + 1000 ) }. Let's talk about any questions you may have and find a time to get you on our schedule.`
  },
  {
    primary: 'Welcome New Customer',
    secondary: 'Automatic quote based on the price per square foot you provided, with language welcoming new customer',
    createHref: options => `mailto:${ options.email }?subject=Paving Quote&body=Hi ${ options.firstName } - thank you for choosing ${ options.name }! I had a chance to review your request for an estimate. Based on your estimated paving area, the job will start around ${ format.money( options.estimate - 500 ) }. Let's talk about getting you on our schedule, and we look forward to working with you soon!`
  },
]


class Response extends React.Component {
  state = {
    response: null,
    isOpen: false
  }

  componentDidMount = () => {
    return Axios.get(
      `/api/responses/${ this.props.match.params.responseId }`
    ).then(_response => {
      const response = ResponseModel.fromJSON(
        _response.data.response
      );

      return this.setState({ response });
    })
  }


  fetchQuestionByKey = (key, defaultObject={ value: '' }) => (
    !this.state.response ?
      defaultObject
    :
      this.state.response.responseJson.find(
        r => r.key === key
      ) || defaultObject
  )


  render(){
    const { classes } = this.props;

    const keysToSkip = [
      AREA_MAP_KEY,
      CONTACT_KEY,
      ADDRESS_KEY,
      PHOTO_KEY,
      VIDEO_KEY
    ];


    const areaMapQuestion = this.fetchQuestionByKey(
      AREA_MAP_KEY
    );

    const contactQuestion = this.fetchQuestionByKey(
      CONTACT_KEY
    );

    const addressQuestion = this.fetchQuestionByKey(
      ADDRESS_KEY
    );

    const photoQuestion = this.fetchQuestionByKey(
      PHOTO_KEY,
      { urls: [] }
    );

    const videoQuestion = this.fetchQuestionByKey(
      VIDEO_KEY,
      { urls: [] }
    );

    const otherQuestions = (
      !this.state.response ?
        []
      :
        this.state.response.responseJson.filter(
          r => !keysToSkip.includes(
            r.key
          )
        )
    );

    const center = (
      !areaMapQuestion || !areaMapQuestion.latLng ?
        undefined
      :
        `${ areaMapQuestion.latLng.lat }, ${ areaMapQuestion.latLng.lng }`
    );

    const squareFeet = (
      areaMapQuestion ?
        areaMapQuestion.areaSquareFeet
      :
        0
    ) || 0;

    const estimate_dollar_and_cents = (
      squareFeet * this.props.pricePerSquareFoot()
    );

    console.log('areaMapQuestion', areaMapQuestion)
    return(
      <MaxWidth maxWidth={1200}>
        <div className={classes.body}>
          <div className={classes.logoDiv}>
            <Tooltip title='View Jobs'>
              <IconButton style={{ float: 'left' }}>
                <Link
                  component={'a'}
                  to={`/${ this.props.hashedCompanyId }`}
                >
                  <Assignment color='primary'/>
                </Link>
              </IconButton>
            </Tooltip>

            <Link
              component={'a'}
              to={`/${ this.props.hashedCompanyId }`}
            >
              <img
                src={this.props.companyDetails.logoUrl || ''}
                className={classes.logo}
              />
            </Link>
          </div>

          {
            this.state.response ?
              null
            :
              <LinearProgress />
          }

          <Grid container spacing={16}>
            <Grid item xs={12} md={6}>
              {
                photoQuestion.urls && photoQuestion.urls.length ?
                  <>
                    <Typography variant='caption' className={classes.response}>
                      Customer-Uploaded Photos
                    </Typography>

                    {
                      photoQuestion.urls.map(
                        url => (<img src={url} className={classes.image}/>)
                      )
                    }
                  </>
                :
                  null
              }

              {
                videoQuestion.urls && videoQuestion.urls.length ?
                  <>
                    <Typography variant='caption' className={classes.response}>
                      Customer-Uploaded Videos
                    </Typography>

                    {
                      videoQuestion.urls.map(
                        url => (
                          <video className={classes.video} controls>
                            <source src={url} type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                        )
                      )
                    }
                  </>
                :
                  null
              }

              <Typography variant='caption'>
                Customer's Location
              </Typography>

              <GoogleMapsMap
                mapMode={'view'}
                parameters={{
                  center,
                  zoom: 20
                }}
              />

              <GoogleMapsMap
                mapMode={'view'}
                parameters={{
                  center,
                  zoom: 20,
                  maptype: 'satellite'
                }}
              />

              <GoogleMapsMap
                mapMode={'streetview'}
                parameters={{
                  location: center
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant='h6' className={classes.information}>
                Information
              </Typography>

              <Typography variant='body1' className={classes.response}>
                { contactQuestion.value.firstName } { contactQuestion.value.lastName }
              </Typography>

              <Typography variant='body1' className={classes.response}>
                Estimated Quote: <b>{ format.money( estimate_dollar_and_cents ) }</b>
              </Typography>

              <Typography variant='body1' className={classes.response}>
                <a href={`mailto:${ contactQuestion.value.email }`}>
                  { contactQuestion.value.email }
                </a>
              </Typography>

              <Typography variant='body1' className={classes.response}>
                <a href={`tel:${ contactQuestion.value.phoneNumber }`}>
                  { contactQuestion.value.phoneNumber }
                </a>
              </Typography>

              <Typography variant='body1' className={classes.response}>
                { addressQuestion.value.fullAddress }
              </Typography>

              {
                !areaMapQuestion || !areaMapQuestion.polygon ?
                  null
                :
                  <Typography variant='body1' className={classes.response}>
                    <Button
                      onClick={() => this.setState({ isOpen: true })}
                      component='a'
                      variant='outlined'
                      color='primary'
                    >
                      View Pavement Area
                    </Button>
                  </Typography>
              }

              {/* {
                otherQuestions.map(
                  q => (
                    <>
                      <Typography variant='caption' className={classes.question}>
                        { q.label }
                      </Typography>

                      <Typography variant='body1' className={classes.response}>
                        { q.value }
                      </Typography>
                    </>
                  )
                )
              } */}

              <Paper className={classes.paper}>
                <Typography variant='body1'>
                  Need help getting started? Start with a customized premade email response to the customer.
                </Typography>

                <List>
                  {
                    PREMADE_RESPONSES.map(
                      (r, i) => {
                        console.log('contactQuestion', contactQuestion)
                        const href = r.createHref({
                          email: contactQuestion.value.email,
                          firstName: contactQuestion.value.firstName,
                          estimate: estimate_dollar_and_cents,
                          name: this.props.companyDetails.name
                        })
                        return (
                          <ListItem
                            key={i}
                            component={'a'}
                            href={href}
                            className={classes.listItem}
                          >
                            <ListItemText
                              primary={r.primary}
                              secondary={r.secondary}
                            />
                          </ListItem>
                        )
                      }
                    )
                  }
                </List>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <AreaMapDialog
          latLng={areaMapQuestion.latLng}
          polygon={areaMapQuestion.polygon}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        />
      </MaxWidth>
    );
  }
}


const styles = theme => ({
  body: {
    marginTop: 50
  },
  paper: {
    marginTop: 15
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'whitesmoke'
    }
  },
  logo: {
    maxWidth: 250
  },
  logoDiv: {
    width: '100%',
    textAlign: 'center'
  },
  information: {
    marginBottom: 15
  },
  response: {
    marginBottom: 10
  },
  question: {

  },
  image: {
    maxWidth: '100%',
    maxHeight: 250,
    marginBottom: 10
  },
  video: {
    maxWidth: '100%',
    maxHeight: 250,
    marginBottom: 10
  },
  paper: {
    padding: 15,
    marginTop: 15
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'whitesmoke'
    }
  }
})


export default withStyles(styles)(
  withContext(
    CompanyDetailContext,
    SessionContext,
    Response
  )
)