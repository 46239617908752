import React from 'react';

import { Router, Route, Switch } from 'react-router-dom'

import Home from './Home/Home';
import Response from './Response/Response';
import SessionProvider from './SessionProvider/SessionProvider';
import CompanyDetailProvider from './CompanyDetailProvider/CompanyDetailProvider';
import ThemeProvider from './ThemeProvider/ThemeProvider';
import history from './history';


class App extends React.Component {
  render() {
    return (
      <SessionProvider companyId={'6862r7yeznj58v478kgwxql91po0d3e0'}>
        <CompanyDetailProvider>
          <ThemeProvider>

            <Router history={history}>
              <Switch>
                <Route exact path="/:companyId" component={ Home }/>
                <Route exact path="/responses/:responseId" component={ Response }/>
              </Switch>
            </Router>

          </ThemeProvider>
        </CompanyDetailProvider>
      </SessionProvider>
    )
  }
}



export default App;