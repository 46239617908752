import React from 'react';
import Axios from 'axios';

import { hashIdCoderInitiator } from '../hashIdCoder'


const DEFAULT_STATE = {
  mapsApiKey: ''
}


export const SessionContext = React.createContext(DEFAULT_STATE);


const CODE_LENGTH = 32;


class SessionProvider extends React.Component {
  constructor(props){
    super(props);

    this.hashIdCoder = hashIdCoderInitiator(
      CODE_LENGTH
    );

    this.state = {
      ...DEFAULT_STATE,
      companyId: this.hashIdCoder.decode(
        props.companyId
      ),
      hashedCompanyId: props.companyId
    };
  }


  componentDidMount = () => {
    return this.fetchMapsApiKey();
  }


  fetchMapsApiKey = () => {
    return Axios.get(
      '/api/responses/maps/client'
    ).then(
      response => this.setState({ mapsApiKey: response.data.client })
    );
  }


  render(){
    return (
      <SessionContext.Provider
        value={{
          ...this.state
        }}
      >
        { this.props.children }
      </SessionContext.Provider>
    )
  }
}


export default SessionProvider