import React from 'react';
import Axios from 'axios';

import { withContext } from 'kn-react';
import { SessionContext } from '../SessionProvider/SessionProvider';
import CompanyDetailModel from './CompanyDetailModel';
import { AREA_MAP_KEY } from '../Response/StepKeys';


const DEFAULT_STATE = {
  companyDetails: {}
}


export const CompanyDetailContext = React.createContext(DEFAULT_STATE);



class CompanyDetailProvider extends React.Component {
  constructor(props){
    super(props);


    this.state = {
      ...DEFAULT_STATE
    };
  }


  componentDidMount = () => {
    return this.fetchCompanyDetail();
  }



  fetchCompanyDetail = () => {
    return Axios.get(
      `/api/company_details/companies/${ this.props.companyId }`
    ).then(response => {
      return this.setState({
        companyDetails: CompanyDetailModel.fromJSON(
          response.data.company_details
        )
      });
    })
  }


  onUpdateCompanyDetail = quoteFormulaJson => {
    return Axios.put(
      `/api/company_details/companies/${ this.props.companyId }`,
      { quote_formula_json: quoteFormulaJson }
    ).then( this.fetchCompanyDetail );
  }


  pricePerSquareFoot = () => {
    if(
      !this.state.companyDetails ||
      !this.state.companyDetails.quoteFormulaJson
    ){
      return 0;
    }


    const pricePerSquareFootEstimator = this.state.companyDetails.quoteFormulaJson.find(
      q => q.key === AREA_MAP_KEY
    );

    if( !pricePerSquareFootEstimator ){
      return 0;
    }


    return +pricePerSquareFootEstimator.value || 0;
  }


  render(){
    return (
      <CompanyDetailContext.Provider
        value={{
          ...this.state,
          onUpdateCompanyDetail: this.onUpdateCompanyDetail,
          pricePerSquareFoot: this.pricePerSquareFoot
        }}
      >
        { this.props.children }
      </CompanyDetailContext.Provider>
    )
  }
}


export default withContext(
  SessionContext,
  CompanyDetailProvider
)