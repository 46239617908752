import React from 'react';

import { withContext } from 'kn-react';
import { SessionContext } from '../SessionProvider/SessionProvider';


const googleMapsMap = props => {
  if( !props.mapsApiKey ){
    return <></>;
  }


  let parameterString = Object.keys( props.parameters ).map(
    k => `${ k }=${ props.parameters[ k ] }`
  ).join('&');

  // Refer to https://developers.google.com/maps/documentation/embed/embedding-map#choosing_map_modes
  if( parameterString ){
    parameterString = '&' + parameterString;
  }


  return (
    <iframe
      width={props.width}
      height={props.height}
      frameBorder="0"
      style={{ border:0 }}
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/${ props.mapMode }?key=${ props.mapsApiKey }${ parameterString }`}
      allowfullscreen
    />
  );
}


googleMapsMap.defaultProps = {
  width: 450,
  height: 250,
  mapMode: 'place',
  parameters: {}
}


export default withContext(
  SessionContext,
  googleMapsMap
)